{
  "button": {
    "back": "Back",
    "cancel": "Cancel",
    "confirm": "Confirm",
    "no": "No",
    "remove": "Remove",
    "download": "Download",
    "clear_all": "Clear all",
    "fullscreen_enter": "Enter fullscreen",
    "fullscreen_exit": "Exit fullscreen",
    "full": "Full",
    "regular": "Regular",
    "exit": "Exit",
    "enter_another_card": "Enter another card",
    "enter_another_code": "Enter another code",
    "submit_code": "Submit code",
    "try_again": "Try again",
    "refresh": "Refresh",
    "rename": "Rename",
    "toggle_dark": "Toggle dark mode",
    "toggle_langs": "Change languages",
    "delete": "Delete",
    "edit": "Edit",
    "buy_now": "Buy now",
    "go_to_homepage": "Go to Homepage",
    "slide_back": "Previous slide",
    "slide_forward": "Next slide",
    "get": "Get"
  },
  "common": {
    "loading": "Loading...",
    "loading_failed": "Loading failed",
    "empty_list": "The list is empty. Come back later.",
    "empty_search": "No results found with a \"{searchValue}\" title.",
    "empty_events_list": "There are no events yet",
    "data_not_found": "Data not found or server not responding. Please try again later.",
    "get_mobile_app": "Get best experience to \n{appName}"
  },
  "not-found": "Not found",
  "locales": {
    "en": "English",
    "de": "German",
    "es": "Spanish",
    "pt": "Portugal",
    "uk-UA": "Ukrainian",
    "pl": "Polish",
    "fr": "French",
    "he": "Hebrew",
    "it": "Italian",
    "ca": "Catalan",
    "ko": "Korean",
    "zh": "Chinese",
    "bs": "Lokalni",
    "title": "Choose website language"
  },
  "baseHeader": {
    "menu": {
      "select_language": "Select language",
      "account": "Your account",
      "manage_following": "Manage following",
      "selected_videos": "Selected videos",
      "logout": "Log Out",
      "language": "Language: {currentLanguage}",
      "my_clips_and_highlights": "My clips/highlights"
    }
  },
  "baseFooter": {
    "rights": "All rights reserved"
  },
  "labels": {
    "access_code": "Enter access code",
    "ppv_price": "Watch from {price}",
    "email": "Email",
    "postal_code": "Postal code",
    "fullName": "Full Name",
    "archive": "Archive",
    "videos": "Videos",
    "category": "Category",
    "league": "League",
    "continue_with": "Continue with {provider}",
    "panoramic_mode": "You are in panoramic mode",
    "password": "Password",
    "sign_in": "Sign in",
    "sign_up": "Sign up",
    "continue": "Continue",
    "newest": "Newest",
    "oldest": "Oldest",
    "dark": "Dark",
    "light": "Light",
    "here": "Here",
    "more": "More",
    "less": "Less",
    "powered_by": "Powered By",
    "forgot_password": "Forgot password",
    "reset_password": "Please enter your new password",
    "password_not_match": "Passwords don't match",
    "see_all": "See all >",
    "highlights": "Highlight | Highlights",
    "clip": "Clip | Clips",
    "tag": "Tag | Tags",
    "latest": "Latest",
    "upcoming": "Upcoming",
    "statistics": "Statistics",
    "stats": "Stats",
    "subscriptions": "Subscriptions",
    "duration": "Duration: expires {duration} {units} after purchase | Duration: expires {duration}",
    "durationRenew": "Duration: will be renewed {duration} {units} after purchase | Duration: will be renewed on {duration}",
    "limited_access": "Limited access",
    "access_time": "You will get access to",
    "full_access": "Full access",
    "ppv_tickets": "PPV tickets | PPV Ticket",
    "live": "Live",
    "events": "Events",
    "back_to_site": "Back to site",
    "all_results": "All Results",
    "save": "Save",
    "password_old": "Old Password",
    "password_new": "New Password",
    "password_repeat": "Repeat Password",
    "enter_new_password": "Enter new password",
    "enter_old_password": "Enter old password",
    "confirm_new_password": "Confirm new password",
    "min_8_characters": "Minimum 8 characters",
    "update": "Update",
    "update_card": "Update card",
    "date_added": "Added date:",
    "release_date": "Release date",
    "my_club": "My Club",
    "select_your_club": "Select Your Club",
    "date": "Date",
    "type": "Type",
    "name": "Name",
    "amount": "Amount",
    "country": "Country",
    "full_name": "Full Name",
    "phone": "Phone",
    "search": "Search",
    "search_by_sport_type": "Search by sport type",
    "search_teams": "Search teams",
    "search_event_league_team": "Search for an event, league or team",
    "load_more": "Load more",
    "follow": "Follow",
    "following": "Following",
    "suggested": "Suggested",
    "go_to_event": "Go to event",
    "go_to_account": "Go to account",
    "go_to_main_page": "Go to main page",
    "page_not_found": "Page not found",
    "total": "Total",
    "all": "All",
    "or": "or",
    "sport_type": "Sport type",
    "event_date": "Event date",
    "anytime": "Anytime",
    "today": "Today",
    "yesterday": "Yesterday",
    "past_7_days": "Past 7 days",
    "past_30_days": "Past 30 days",
    "past_90_days": "Past 90 days",
    "years": "Years | year | years",
    "months": "Months | month | months",
    "weeks": "Weeks | week | weeks",
    "days": "Days | day | days",
    "hours": "Hours | hour | hours",
    "minutes": "Minutes | minute | minutes",
    "seconds": "Seconds | second | seconds",
    "terms": "Terms of Service",
    "policy": "Privacy Policy",
    "next": "Next",
    "done": "Done",
    "skip": "Skip",
    "game_recap": "Game recap",
    "play_by_play": "Play-by-play",
    "search_input_placeholder": "Search schools, clubs, leagues etc.",
    "modal_labels": {
      "delete_tag": "Are you sure you want to remove these tags? | Are you sure you want to remove this tag?",
      "delete_profile": "Are you sure you want to delete your profile?",
      "delete_clip": "Are you sure you want to remove this clip?",
      "delete_highlight": "Are you sure you want to remove this highlight?"
    },
    "gameType_labels": {
      "practice": "Practice",
      "training": "training",
      "other": "Other",
      "game": "Game"
    },
    "submit": "Submit",
    "active": "Active",
    "inactive": "Inactive",
    "purchase": "Purchase",
    "change_product": "Change product",
    "views": "no views | {count} view | {count} views",
    "game_videos": "Game videos",
    "your_clips": "Your clips",
    "your_clips_highlight": "Your clips/highlights",
    "game_highlights": "Game highlights",
    "player_highlights": "Player highlights",
    "watch_full_game": "Watch full game",
    "create_highlight": "Create highlight",
    "share": "Share",
    "bookmark_add": "Add to Selected",
    "bookmark_remove": "Remove from selected",
    "latest_events": "Latest events",
    "latest_highlights": "Latest highlights",
    "commerce_category_labels": {
      "member": "Member",
      "free": "Free",
      "ppv": "Pay Per View",
      "subscription": "Subscription",
      "rental": "Rental",
      "premium": "Premium"
    },
    "lock_event_message": {
      "member": "Please sign in to watch the game",
      "premium": "Subscribe to Watch",
      "geo_blocking": "We are sorry, but the video you are trying to play is restricted from your region",
      "event_restricted": "This event is restricted for your location"
    },
    "main": "Main",
    "add_to_selected": "Add to Selected",
    "remove_from_selected": "Remove from Selected",
    "team_1_logo": "Team 1 Logo",
    "team_2_logo": "Team 2 Logo",
    "team": "Team"
  },
  "pages": {
    "events": {
      "event_start_date": "Event will start on {date}",
      "time_till_kick_off": "Time till kick off:",
      "event_will_start_in_a_few_min": " The event will start automatically in a few minutes...",
      "appreciation": "Thank you!",
      "purchased_declined": "Payment was declined",
      "failed_purchased_message": "Please try again or enter another card.",
      "failed_purchased_message_2": "Please try again or use another payment method.",
      "success_purchased": "You successfully purchased {subscription}",
      "success_purchased_secondary": "Your purchase was successful",
      "success_code_applied": "{title} code applied successfully",
      "failed_code_applied_title": "Invalid code",
      "submit_reedem_code_input_placeholder": "Input your access code here",
      "failed_code_applied_message": "Please try again or enter another code.",
      "failed_already_used_code": "This code has been already used",
      "failed_statistics": "Failed to load statistics related to this event.. Please try again later.",
      "game_video_login_message": "Please sign in to view game videos",
      "tags_login_message": "Please sign in to view tags",
      "modal_share_title": "Share this video",
      "modal_share_with_time": "Start at {time}",
      "viewing_conditions": "Choose your viewing conditions",
      "watching_event_by_text": "You are watching this event by",
      "tabs": {
        "event_videos": {
          "name_the_clip": "Name the clip",
          "create_clip": "Create clip",
          "rename_clip": "Rename clip",
          "clip_deleted_successfully_text": "Clip was successfully deleted",
          "clip_updating_successfully_text": "Clip was successfully updated",
          "title_no_clips": "No clips have been created yet",
          "title_enabled_clipping": "There is no clips in the list",
          "title_no_clips_not_logged_in": "Please sign in to be able to cut clips from this game",
          "clip_creating_successfully_text": "Your clip will soon be ready"
        },
        "event_tags": {
          "name_the_tag": "Name the tag",
          "make_tag_global": "Make tag global",
          "choose_player_color": "Enter a player '#' and the colour of his team (optional)",
          "choose_tag": "Choose tag type",
          "choose_team": "Choose a team",
          "create_tag": "Create tag",
          "update_tag": "Update tag",
          "tag_creating_successfully_text": "Tag was successfully created",
          "tag_updating_successfully_text": "Tag was successfully updated",
          "tag_deleting_successfully_text": "Tag was successfully deleted",
          "title_no_tags": "No tags have been created yet",
          "title_enabled_tagging": "There is no tags in the list",
          "title_no_tags_not_logged_in": "Please sign in to be able to create tags for this game",
          "is_external_content": "This is external content, you cannot create a tag",
          "filters": {
            "search_input_placeholder": "Search for specific tags",
            "popup_title": "Filter",
            "by_type": "By type",
            "by_team": "By team",
            "by_player_number": "Player '#'",
            "clear_all_btn": "Clear all | Clear all ({count}) | Clear all ({count})",
            "show_results_btn": "Show results",
            "no_tags": "No tags matching selected filter",
            "select_all": "Select all | Select all ({count}) | Select all ({count})"
          },
          "play_all_btn": "Play all",
          "pause_all_btn": "Pause all"
        },
        "event_stats": {
          "score_board_title": "Score",
          "game_board_title": "Game Stats",
          "empty_stats_data": "Stats data is empty"
        }
      }
    },
    "videos": {
      "last_viewed": "Last viewed",
      "user_interesting": "You may also like",
      "title_no_items": "Add some videos to selected",
      "description_no_items": "This is the place for your favourite videos. Save, review and share!"
    },
    "clips": {
      "title_no_items_with_selected_filter": "No items found with this filter",
      "title_no_clips": "You don't have any clips yet. Go to the video and create your first clip!",
      "title_no_highlights": "You don't have any highlights yet. Go to the video and create your first highlights!"
    },
    "auth_sign_up": {
      "enter_your_details_below": "Enter your details below",
      "password_updated_successuly_text": "Your password was successfully updated",
      "already_have_account": "Already have an account?",
      "follow_category_header": "Select the categories you want to follow",
      "follow_teams_header": "Select teams you want to follow",
      "back_to_all_teams": "Back to all teams",
      "back_to_all_categories": "Back to all categories"
    },
    "auth_sign_in": {
      "sign_in_with_provider": "Please sign in with your {provider} credentials",
      "welcome_message": "Welcome back",
      "new_to": "New to",
      "auth_terms": "By continuing, you agree to our {terms_of_service} and {privacy_policy}"
    },
    "account": {
      "account_delete_button": "Delete account",
      "account_delete_confirm": "Are you sure you want to delete your profile?",
      "account_update_successfully": "Your account has been updated successfully!",
      "account_delete_successfully": "Your account has been deleted successfully!",
      "account_delete_description": "This action cannot be reversed.",
      "tab_general_title": "General",
      "tab_password_title": "Password",
      "tab_subscription_title": "Subscription",
      "tab_ppv_title": "PPV tickets",
      "tab_ppv_no_ppv": "You don't have PPV tickets.\nPlease go to the event page to buy a ticket.",
      "tab_ppv_access_text": "The PPV ticket gives access to",
      "tab_billing_title": "Billing information",
      "tab_devices_title": "Devices"
    },
    "auth_password": {
      "send_reset_link": "Send reset link",
      "back_to_sign_in": "Back to sign in",
      "set_new_password": "Set new password",
      "resend_instruction": "Resend instructions",
      "forgot_password_help_text": "We'll help you reset it and get back on track.",
      "forgot_password_success_text": "An email with instructions on how to reset your password has been sent to {email}. If you don’t receive the email within a few minutes, click on the Resend instructions button below."
    },
    "categories": {
    "no_following_category_for_logged_user": "You are not following anything at the moment. \nClick {here} to stay up to date with your favourite events",
    "no_following_category_for_unlogged_user": "Please {login} to stay up to date with your favourite events"
    },
    "clubs": {
      "manage_following": "Manage following",
      "choose_your_club": "Choose your club",
      "choose_your_club_message": "Choose the club you will be associated with"
    },
    "club": {
      "favorite_club_creating_successfully_text": "Your club was successfully chosen"
    },
    "auth_login_limit": {
      "device_limit_reached": "Devices limit reached",
      "remove_device_to_login": "Please remove one to login",
      "current_device": "Current device"
    },
    "auth_login": {
      "device_no_longer_allowed_error": "Your current device is no longer in the list of allowed devices. Please sign in again."
    },
    "account_subscription": {
      "button_subscribe_now": "Subscribe now",
      "payment_details": "{title}: Payment details",
      "active_subscription": "Active subscription",
      "subscription_expires": "Subscription expires {date}",
      "renew_subscription_expires": "Subscription is recurring. Next charge date {date}",
      "subscription_next_charge_date": "Next charge | Next charge date",
      "subscription_expiration_date": "Expired by",
      "list_subscriptions_empty": "List of available subscriptions is empty. Come back later",
      "subscription_due_date": "Due date",
      "subscription_last_payment_date": "Last payment date",
      "button_subscription_cancel": "Cancel subscription",
      "button_subscription_keep": "Keep subscription",
      "redeem_access_code_label": "Redeem your Access Code here",
      "redeem_access_code_title": "Redeem your Access Code",
      "or_select_plan": "or select your plan",
      "button_back_to_my_subscription": "Back to my subscription",
      "button_back_to_my_tickets": "Back to my tickets",
      "checkbox_apply_as_new_credit_card": "Save card details",
      "label_plan_amount": "Plan amount",
      "label_taxes": "Taxes",
      "label_total_billed": "Total billed",
      "label_full_access": "Full access",
      "label_limited_access": "Limited access",
      "label_full_access_description": "Full access to all content on website",
      "label_limited_access_description": "Limited access to all content on website",
      "alert_access_code_redeemed_success": "Access code redeemed successfully",
      "alert_access_code_redeem_not_found": "Requested access code doesn't exist",
      "alert_access_code_redeem_code_used": "Requested access code already used",
      "alert_subscription_cancel_success": "Subscription cancelled successfully",
      "tab_my_subscriptions": "My Subscriptions",
      "tab_all_subscriptions": "All Subscriptions",
      "no_subscriptions_text": "There are no active subscriptions in the list. \nCome back later",
      "cancel_subscription_modal_title": "Are you sure you want to cancel your subscription?",
      "cancel_subscription_modal_subtitle": "Your subscription will be cancelled at the end of your billing period on {date}. You can change your mind any time before this date."
    },
    "account_billing": {
      "card_holder_name": "Name on card",
      "card_number": "Card number",
      "card_expiry_date": "Expiry date",
      "card_cvv": "CVC / CVV",
      "button_back_to_billing_info": "Back to billing information",
      "payment_history_empty_text": "Your payment history is empty",
      "payment_history_title": "Payment history",
      "payment_in_progress": "Payment in progress",
      "payment_in_progress_message":"Please don’t close this page until your payment is processed.",
      "alert_credit_card_save_success": "Your credit card information has been saved successfully",
      "alert_credit_card_update_success": "Your credit card information has been updated successfully",
      "label_modal_confirm_credit_card_delete": "Once you remove your credit card, your subscription will be cancelled as well. The content is still available for you until the end date of the plan. Are you sure you want to remove the credit card?",
      "alert_credit_card_delete_success": "Your billing card has been deleted successfully"
    },
    "account_password": {
      "alert_password_update_success": "Your password has been changed successfully"
    },
    "league": {
      "all_rounds": "All Rounds"
    }
  },
  "errors": {
    "incomplete_number": "Your card number is incomplete!",
    "popup_closed": "Popup was closed",
    "login_failed": "Login failed",
    "invalid_number": "Your card number is invalid!",
    "incomplete_expiry": "Your card's expiration date is incomplete!",
    "invalid_expiry_month_past": "Your card's expiration year is in the past!",
    "invalid_expiry_year_past": "Your card's expiration year is in the past!",
    "invalid_expiry_year": "Your card's expiration year is invalid!",
    "incomplete_cvc": "Your card's security code is incomplete!",
    "device_limit_exceeded": "Device limit exceeded!",
    "passwords_dont_match": "Passwords don't match",
    "vod_corrupted_files": "The video could not be loaded",
    "expired_session_error": "Your session has expired. Please sign in again.",
    "vod_files_not_found": "Oops! Video not found",
    "vod_playback_failed": "Oops! Playback failed during VOD stream",
    "page_not_found":"Page not found",
    "event_not_found":"Event not found",
    "league_not_found":"League not found",
    "team_not_found":"Team not found",
    "could_not_find_page":"Sorry, we couldn’t find the page you are looking for.",
    "could_not_find_event":"Sorry, we couldn’t find the event you are looking for.",
    "could_not_find_team":"Sorry, we couldn’t find the team you are looking for.",
    "could_not_find_league": "Sorry, we couldn’t find the league you are looking for.",
    "could_not_find_results": "Sorry, we couldn’t find any results for this search.",
    "default_error_message": "Server error occurred. Please try again later",
    "ERR400GN00": "Bad Request",
    "ERR401GN00": "Unauthorized",
    "ERR402GN00": "Payment Required",
    "ERR403GN00": "Forbidden",
    "ERR404GN00": "Not Found",
    "ERR409GN00": "Conflict",
    "ERR422GN00": "Unprocessable Content",
    "ERR451GN00": "Unavailable For Legal Reason",
    "ERR400GN11": "Invalid ID provided",
    "ERR400GN12": "Not updatable fields provided",
    "ERR500GN00": "Internal Server Error",
    "ERR501GN00": "Not Implemented",
    "ERR502GN00": "Bad Gateway",
    "ERR503GN00": "Service Unavailable",
    "ERR504GN00": "Gateway Timeout",
    "ERR400UR11": "Profile first name and last name are required",
    "ERR400UR12": "Email is required",
    "ERR400UR13": "The user already has a stored Credit Card",
    "ERR400UR14": "Please complete the first name, last name and email fields of your profile before save a Credit Card",
    "ERR400UR17": "User has no credit card to edit",
    "ERR400UR18": "Subscription is already cancelled",
    "ERR400UR19": "Subscription is not active",
    "ERR400UR20": "Username and password are required",
    "ERR400UR21": "Account is not active",
    "ERR400UR24": "The old password is wrong. Please check your credential",
    "ERR401UR11":  "The username or password is wrong. Please check your credentials.",
    "ERR404UR00": "User not found",
    "ERR404UR11": "Order was not found",
    "ERR404UR12": "Subscription was not found",
    "ERR404UR13": "User does not exist",
    "ERR404UR14": "Profile does not exist",
    "ERR404UR15": "Card was not found for this user",
    "ERR404UR16": "Video not found at watchlist",
    "ERR409UR11": "Club is already added",
    "ERR409UR12": "User already registered",
    "ERR400CM11": "Payment failed",
    "ERR400CM12": "Place order failed",
    "ERR400CM13": "Capture payment failed",
    "ERR400CM14": "Subscription save merchant info failed",
    "ERR400CM15": "Please check your credit card information",
    "ERR400CM16": "Invalid platform value provided",
    "ERR404CM11": "Cannot get subscriptions",
    "ERR404CM12": "Access code not found",
    "ERR409CM11": "Access code already in use",
    "ERR404EV11": "Can not get event information",
    "ERR404EV12": "Event or VOD not found",
    "ERR400ID11": "Invalid identity provided",
    "ERR400ID12": "Invalid identity item provided",
    "ERR400ID13": "Identity can not be added to club list",
    "ERR400ID14": "Invalid pair of identity and identity item provided",
    "ERR404ID00": "Identity not found",
    "ERR404ID11": "Identity could not be retrieved",
    "ERR404PR00": "Product Not Found",
    "ERR404PR11": "Can not get ppv information",
    "ERR409PR12": "Product already purchased",
    "ERR400TG11": "Failed to create tag",
    "ERR403TG11": "User can not update global tags",
    "ERR403TG12": "User can not create global tags",
    "ERR403TG13": "User cannot delete the tags that was not created by one",
    "ERR403TG14": "User cannot delete global tags",
    "ERR404TG00": "Tag not found",
    "ERR400CL11": "Failed to create clip",
    "ERR404TM00": "Team not found",
    "ERR404VD11": "Vod could not be retrieved",
    "ERR500NT12": "Sending the reset password email failed",
    "ERR401AT11": "Token is required",
    "ERR401AT12": "Invalid password reset token",
    "ERR401AT13": "Invalid refresh token",
    "ERR401AT14": "Unknown subject issuer",
    "ERR401AT15": "Unsupported subject token type",
    "ERR401AT16": "Invalid token",
    "ERR401AT17": "Expired token",
    "ERR401AT18": "Failed to obtain an email from the token",
    "ERR401AT19": "User not found"
  },
  "sport_types": {
    "americanFootball": "American football",
    "beachvolleyball": "Beach volleyball",
    "fieldhockey": "Field hockey",
    "icehockey": "Ice hockey",
    "rollerhockey": "Roller hockey",
    "floorball": "Floorball",
    "softball": "Softball",
    "waterpolo": "Waterpolo",
    "badminton": "Badminton",
    "bowling": "Bowling",
    "futsal": "Futsal",
    "lacrosse": "Lacrosse",
    "rugby": "Rugby",
    "swimming": "Swimming",
    "wrestling": "Wrestling",
    "baseball": "Baseball",
    "cricket": "Cricket",
    "gymnastics": "Gymnastics",
    "netball": "Netball",
    "snooker": "Snooker",
    "tennis": "Tennis",
    "basketball": "Basketball",
    "handball": "Handball",
    "other": "Other",
    "soccer": "Soccer",
    "football": "Football",
    "volleyball": "Volleyball"
  },
  "footer_links": {
    "about_us": "About",
    "faq": "FAQ",
    "privacy_policy": "Privacy",
    "terms_of_service": "Terms",
    "custom": "{custom}"
  },
  "countries": {
    "AF": "Afghanistan",
    "AX": "Åland Islands",
    "AL": "Albania",
    "DZ": "Algeria",
    "AS": "American Samoa",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AG": "Antigua and Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AC": "Ascension Island",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaijan",
    "BS": "Bahamas",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarus",
    "BE": "Belgium",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia",
    "BA": "Bosnia and Herzegovina",
    "BW": "Botswana",
    "BR": "Brazil",
    "IO": "British Indian Ocean Territory",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Cambodia",
    "CM": "Cameroon",
    "CA": "Canada",
    "IC": "Canary Islands",
    "BQ": "Caribbean Netherlands",
    "KY": "Cayman Islands",
    "CF": "Central African Republic",
    "EA": "Ceuta and Melilla",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Christmas Island",
    "CV": "Cape Verde",
    "CC": "Cocos (Keeling) Islands",
    "CO": "Colombia",
    "KM": "Comoros",
    "CG": "Congo",
    "CK": "Cook Islands",
    "CR": "Costa Rica",
    "HR": "Croatia",
    "CU": "Cuba",
    "CY": "Cyprus",
    "CZ": "Czechia",
    "DK": "Denmark",
    "DG": "Diego Garcia",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominican Republic",
    "EC": "Ecuador",
    "EG": "Egypt",
    "SV": "El Salvador",
    "GQ": "Equatorial Guinea",
    "ER": "Eritrea",
    "EE": "Estonia",
    "ET": "Ethiopia",
    "FK": "Falkland Islands",
    "FO": "Faroe Islands",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "France",
    "GF": "French Guiana",
    "PF": "French Polynesia",
    "TF": "French Southern Territories",
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Georgia",
    "DE": "Germany",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Greece",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HN": "Honduras",
    "HU": "Hungary",
    "IS": "Iceland",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IL": "Israel",
    "IM": "Isle of Man",
    "IT": "Italy",
    "JM": "Jamaica",
    "JP": "Japan",
    "JE": "Jersey",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "XK": "Kosovo",
    "KI": "Kiribati",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "MK": "North Macedonia",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshall Islands",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Micronesia",
    "MD": "Moldova",
    "MC": "Monaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MA": "Morocco",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Netherlands",
    "NC": "New Caledonia",
    "NZ": "New Zealand",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolk Island",
    "MP": "Northern Mariana Islands",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PN": "Pitcairn Islands",
    "PH": "Philippines",
    "PL": "Poland",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "RO": "Romania",
    "RW": "Rwanda",
    "WS": "Samoa",
    "SM": "San Marino",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "RS": "Serbia",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SX": "Sint Maarten",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SO": "Somalia",
    "SB": "Solomon Islands",
    "ZA": "South Africa",
    "GS": "South Georgia and the South Sandwich Islands",
    "SS": "South Sudan",
    "ES": "Spain",
    "LK": "Sri Lanka",
    "SD": "Sudan",
    "SR": "Suriname",
    "SZ": "Eswatini",
    "SE": "Sweden",
    "CH": "Switzerland",
    "TW": "Taiwan",
    "TJ": "Tajikistan",
    "TH": "Thailand",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad and Tobago",
    "TA": "Tristan da Cunha",
    "TN": "Tunisia",
    "TR": "Turkey",
    "TM": "Turkmenistan",
    "TC": "Turks and Caicos Islands",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraine",
    "AE": "United Arab Emirates",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela",
    "WF": "Wallis and Futuna",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe",
    "VG": "British Virgin Islands",
    "BN": "Brunei",
    "CD": "Congo, Dem. Rep. of (Zaire)",
    "CW": "Curacao",
    "TL": "Timor-Leste",
    "HK": "Hong Kong SAR China",
    "CI": "Côte d'Ivoire",
    "LA": "Laos",
    "MO": "Macau SAR China",
    "KP": "North Korea",
    "PS": "Palestinian Territory",
    "RE": "Reunion",
    "RU": "Russia",
    "KR": "South Korea",
    "SY": "Syria",
    "TZ": "Tanzania",
    "VI": "U.S. Virgin Islands",
    "GB": "United Kingdom",
    "US": "United States",
    "VA": "Vatican City",
    "VN": "Vietnam",
    "EH": "Western Sahara"
  },
  "tags": {
    "other_wow": "other wow",
    "general_bad": "bad",
    "general_good": "good",
    "general_drill": "drill",
    "basketball_dunk": "dunk",
    "basketball_three_point": "three point",
    "basketball_big_play": "big play",
    "basketball_alley_oop": "alley oop",
    "basketball_assist": "assist",
    "basketball_block": "block",
    "basketball_momentym": "momentum",
    "basketball_money_time": "money time",
    "basketball_substitution": "substitution",
    "soccer_goal": "goal",
    "soccer_penalty": "penalty",
    "soccer_shot_on_target": "shot on target",
    "soccer_yellowcard": "yellow card",
    "soccer_redcard": "red card",
    "soccer_foul": "foul",
    "soccer_corner_kick": "corner kick",
    "soccer_shot": "shot",
    "soccer_free_kick": "free kick",
    "soccer_header": "header",
    "soccer_save": "save",
    "soccer_substitution": "substitution",
    "football_touch_down": "touch down",
    "football_field_goal": "field goal",
    "football_big_catch": "big catch",
    "football_big_run": "big run",
    "football_big_hit": "big hit",
    "football_kick_return": "kick return",
    "football_punt_return": "punt return",
    "football_kickoff": "kickoff",
    "football_substitution": "substitution",
    "rugby_break_down": "break down",
    "rugby_conversion": "conversion",
    "rugby_drop_goal": "drop goal",
    "rugby_garry_owen": "garry owen",
    "rugby_grubber": "grubber",
    "rugby_knock_on": "knock on",
    "rugby_maul": "maul",
    "rugby_penalty_try": "penalty try",
    "rugby_substitution": "substitution",
    "volleyball_free_ball": "free ball",
    "volleyball_side_out": "side out",
    "volleyball_spike": "spike",
    "volleyball_dig": "dig",
    "volleyball_double": "double",
    "volleyball_mishit": "mishit",
    "volleyball_shank": "shank",
    "volleyball_serve": "serve",
    "volleyball_set_point": "set point",
    "volleyball_match_point": "match point",
    "volleyball_substitution": "substitution",
    "baseball_stolen_base": "stolen base",
    "baseball_home_run": "home run",
    "baseball_run": "run",
    "baseball_single": "single",
    "baseball_hit": "hit",
    "baseball_double": "double",
    "baseball_tripple": "tripple",
    "baseball_strikeout": "strikeout",
    "baseball_popout": "popout",
    "baseball_ground_out": "ground out",
    "baseball_fly_out": "fly out",
    "baseball_double_play": "double play",
    "baseball_triple_play": "triple play",
    "baseball_walk": "walk",
    "baseball_top_1st": "top 1st",
    "baseball_bottom_1st": "bottom 1st",
    "baseball_top_2nd": "top 2nd",
    "baseball_bottom_2nd": "bottom 2nd",
    "baseball_substitution": "substitution",
    "lacrosse_goal": "goal",
    "lacrosse_shot": "shot",
    "lacrosse_save": "save",
    "lacrosse_clear": "clear",
    "lacrosse_man_up": "man up",
    "lacrosse_penalty": "penalty",
    "lacrosse_face_off": "face off",
    "lacrosse_ground_ball": "ground ball",
    "lacrosse_substitution": "substitution",
    "futsal_goal": "goal",
    "futsal_penalty": "penalty",
    "futsal_shot_on_target": "shot on target",
    "futsal_yellowcard": "yellow card",
    "futsal_redcard": "red card",
    "futsal_foul": "foul",
    "futsal_corner_kick": "corner kick",
    "futsal_shot": "shot",
    "futsal_free_kick": "free kick",
    "futsal_header": "header",
    "futsal_save": "save",
    "futsal_substitution": "substitution",
    "beach_volleyball_free_ball": "free ball",
    "beach_volleyball_side_out": "side out",
    "beach_volleyball_spike": "spike",
    "beach_volleyball_dig": "dig",
    "beach_volleyball_double": "double",
    "beach_volleyball_mishit": "mishit",
    "beach_volleyball_shank": "shank",
    "beach_volleyball_serve": "serve",
    "beach_volleyball_set_point": "set point",
    "beach_volleyball_match_point": "match point",
    "beach_volleyball_substitution": "substitution",
    "field_hockey_goal": "goal",
    "field_penalty_corner": "penalty corner",
    "field_ball_possesion": "ball possesion",
    "field_ball_loss": "ball loss",
    "field_turn_over": "turn over",
    "field_circle_penetration": "circle penetration",
    "field_press_fp_hc": "press",
    "field_interception": "interception",
    "field_time_penalty": "time penalty",
    "field_hockey_fight": "fight",
    "field_hockey_big_hit": "big hit",
    "field_hockey_power_play": "power play",
    "field_hockey_4v4": "4v4",
    "field_hockey_shot": "shot",
    "field_hockey_save": "save",
    "field_hockey_other": "other",
    "field_hockey_substitution": "substitution",
    "hockey_goal": "goal",
    "hockey_fight": "fight",
    "hockey_big_hit": "big hit",
    "hockey_power_play": "power play",
    "hockey_4v4": "4v4",
    "hockey_shot": "shot",
    "hockey_save": "save",
    "hockey_substitution": "substitution",
    "roller_hockey_goal": "goal",
    "roller_penalty_corner": "corner",
    "roller_ball_possesion": "ball possesion",
    "roller_ball_loss": "ball loss",
    "roller_turn_over": "turn over",
    "roller_circle_penetration": "circle penetration",
    "roller_press_fp_hc": "press",
    "roller_interception": "interception",
    "roller_time_penalty": "time penalty",
    "roller_hockey_fight": "fight",
    "roller_hockey_big_hit": "big hit",
    "roller_hockey_power_play": "power play",
    "roller_hockey_4v4": "4v4",
    "roller_hockey_shot": "shot",
    "roller_hockey_save": "save",
    "roller_hockey_other": "other",
    "roller_hockey_substitution": "substitution",
    "handball_goal": "goal",
    "handball_shot_on_goal": "shot on goal",
    "handball_save": "save",
    "handball_foul": "foul",
    "handball_red_card": "red card",
    "handball_yellow_card": "yellow card",
    "handball_suspension": "suspension",
    "handball_penalty_shot": "penalty shot",
    "handball_substitution": "substitution",
    "wrestling_wow": "wow",
    "gymnastic_wow": "wow"
  }
}
